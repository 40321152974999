.rating {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 50px;
    font-size: 50px;
  }
  
.rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
  }
  
.rating label:last-child {
    position: static;
  }
  
.rating label:nth-child(1) {
    z-index: 5;
  }
  
.rating label:nth-child(2) {
    z-index: 4;
  }
  
.rating label:nth-child(3) {
    z-index: 3;
  }
  
.rating label:nth-child(4) {
    z-index: 2;
  }
  
.rating label:nth-child(5) {
    z-index: 1;
  }
  
.rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
.rating label .icon {
    float: left;
    color: transparent;
  }
  
.rating label:last-child .icon {
    color: #000;
  }

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
    color: rgb(255, 208, 0);
  }
  
.rating label input:focus:not(:checked) ~ .icon:last-child {
    color: #000;
    text-shadow: 0 0 5px #09f;
  }

.x_button {
  font-size: 40px;
  margin-left: 15px;
  padding: 0px;
  width: 30px;
  height: 30px;
  line-height: 0em;
  margin-bottom: 0px;
}

.add_button {
  margin-left: 10px;
  font-size: 15px;
  padding: 10px;
  width: 45px;
  margin-bottom: 0px;
  height: 47px;
}

.add_form {
  margin-top: 125px;
}

.add_module{
  margin-top: 10px;
}

.add_form h1 {
  background-color: #A6A49A;
  margin-bottom: 0px;
}

.add_flex {
  display: flex;
  justify-content: center;
}

.comparison_input {
  margin-left: 55px;
}

.display_flex{
  display: flex;
}

.justify_content_center {
  justify-content: center;
}

.align_items_center {
  align-items: center;
}

@media screen and (min-width: 768px) {
  .add_button {
    width: 60px;
    font-size: 20px;
  }

  .comparison_input{
    margin-left: 69px;
  }
}

