.home-page-container{
    display: flex;
    flex-direction: column;
    background: linear-gradient(135deg,#737A55,#595F42);
    color:  white;
    padding-top: 100px;
}

.home-page-header, .home-page-image, .home-page-image-2{
    display: flex;
    flex-direction: column;
    justify-content: top;
    flex: 1;
}

.home-page-image img{
    height: 500px;
    width: auto;
    object-fit: cover;
    padding: 30px;
    border-radius: 50px;
    
}

.home-page-image-2 img{
    height: 400px;
    width: auto;
    object-fit: contain;
    opacity: .75;
}

.white_background {
    background-color: white;
    background-clip: content-box;
}

.home-page-header {
    padding-left: 100px;
    text-align: left;
    padding-top: 40px;
}
header h3 {
    margin-top: 0;
}

.home-page-header h1, .home-page-header p {
    padding-right: 50px;
}

h1, h2, h3 {
    font-family: 'Open Sans', sans-serif;
}

p{
    font-family: 'Roboto Condensed', sans-serif;
}
.home-page-header h1 {
    font-size: 40px;
    line-height: 1.2;
}

.margin-top {
    margin-top: 30px;
}

.signup_button {
    width: 200px;
    background: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
    width: 50%;
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 5px;
    padding-top: 5px;
    
}

  .signup_button:hover {
    color: white;
    background-color: #92654C;
  }

  .signup-form{
      background-color: #302015;
      color: white; 
  }

@media screen and (min-width: 768px) {
    .home-page-container{
        flex-direction: row;
    }

    .signup_button{
        width: 500px;
    }

    .home-page-image{
        height: 100%;
    }
}



