* {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans",
    sans-serif;
    font-size: 20px;
    color: #444;
    text-align: center;
    line-height: 1.5;
    min-width: 320px;
  }
  
  p {
    margin: 0 auto;
    max-width: 650px;
  }
  
  p + p {
    margin-top: 15px;
  }
  
  form {
    text-align: left;
  }
  
  input, textarea {
    width: 100%;
    max-width: 500px;
  }
  
  
  label {
    display: block;
  }
  
  .form-section {
    margin-bottom: 30px;
  }

  .item-list-2, .item-list-3, .item-list-1{
    column-count: 1;
    column-gap: 1em;
    padding: 20px;
  }

  .list-container{
    margin-top: 75px;
  }

  .item{
    background-color: #A6A49A;
    display: inline-block;
    margin: 0 0 1em;
    width: 100%;
    text-align: center;
  }

  .item ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }

.cssCircle:hover {
  background: #92654C ;
  cursor: pointer;
}

.plusSign {
  position: fixed;
  color: black;
  bottom: 40px;
  right: 20px;
  z-index: 1000;
  background-color:#fff;
  border:2px solid black;    
  height:60px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  width:60px;
  line-height: 1.1em;
  text-decoration: none;
  font-size: 43px;
  text-align: center;
}

.plusSign:hover {
  color: white;
  border:2px solid white;
}

.module-title {
  color: white;
  font-size: 50px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

@media screen and (min-width: 768px) {
  .item-list-2{
    column-count: 2;
    column-gap: 1em;
    padding: 20px;
  }

  .item-list-3{
    column-count: 3;
    column-gap: 1em;
    padding: 20px;
  }

  .item-list-1{
    column-count: 1;
    column-gap: 1em;
    padding: 20px;
  }
}
