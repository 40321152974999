form {
    background-color: #A6A49A;
    text-align: center;
}

input {
    width: 200px;
    text-align: center;
    padding: 14px;
}

button, .likeAButton {
    background: white;
    border: none;
    text-align: center;
    padding: 10px 0px;
    width: 50%;
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 5px;
    padding-top: 5px;
}

.buttonV2 {
    background: white;
    border: none;
    text-align: center;
    width: 100%;
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 5px;
    margin-left: 10px;
    padding: 5px 10px; 
}

.margin-right {
    margin-right: 20px;
}

.margin-top {
    margin-top: 125px;
}

  button:hover, .likeAButton:hover, .likeAButton:hover:visited {
    color: white;
    background-color: #92654C;
  }

  .likeAButton:visited {
    color: black;
  }

.nav_buttons{
    display: flex;
    flex-direction: row;
}

.home_button{
    width: 20px;
}

.App_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}
main .container {
    padding-top: 75px;
}

.background {
    background-color: #737A55;  
}

.fade {
    transition: .5s;
}

.App_header h1 {
    color: white;
    text-decoration: none;
}

.header_link {
    font-family: 'Girassol', cursive;
    color:white;
    padding-left: 10px;
    font-size: 25px;
    
}

a {
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    input {
      width: 500px;
    }

    .header_link{
        font-size: 50px;
        padding-left: 30px;
    }
  }