* {
    box-sizing: border-box;
  }
  
  body {
    font-family: "Open Sans",
    sans-serif;
    font-size: 20px;
    color: #444;
    text-align: center;
    line-height: 1.5;
    min-width: 320px;
    background-color: #737A55;
  }

  .container section {
    position: relative;
    text-align: center;
    color: black;
  }

  .centered {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  
  p {
    margin: 0 auto;
    max-width: 650px;
  }
  
  p + p {
    margin-top: 15px;
  }
  
  form {
    text-align: left;
  }
  
  input, textarea {
    width: 100%;
    max-width: 500px;
  }
  
  label {
    display: block;
  }
  
  .form-section {
    margin-bottom: 30px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
  }

  .container {
    min-height: 100%;
    flex-wrap: wrap;
    margin-top: 25px;
  }

  .container section{
    display: block; 
    flex-basis: calc(50% - 40px);  
    min-height: 400px;
    font-size: 40px;
  }

  .container section div {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }

  .venues{
    background-image: url('../Pictures/venue.jpg');
    background-color: rgba(32, 31, 29, 0.5); 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .caterers{
    background-image: url('../Pictures/caterer.jpg');
    background-color: rgba(32, 31, 29, 0.5); 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .florists{
    background-image: url('../Pictures/florist.jpg');
    background-color: rgba(32, 31, 29, 0.5); 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .photographers{
    background-image: url('../Pictures/photographer.jpg');
    background-color: rgba(32, 31, 29, 0.5); 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .guests{
    background-image: url('../Pictures/guest.jpg');
    background-color: rgba(32, 31, 29, 0.5); 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .music{
    background-image: url('../Pictures/music.jpg');
    background-color: rgba(32, 31, 29, 0.5); 
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media screen and (min-width: 768px) {
    .flex-container{
      flex-direction: row;
      justify-content: center;
    }

    .container section{  
      min-height: 300px;
    }
  }
